import React from 'react';
import loadable from '@loadable/component';
import { getCart } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';

const component = loadable(() => import('./CartPageContainer'));
component.preloadData = [
  getCart,
];
component.getHeader = () => {
  return {
    left:   <Button tag="a" to="/" filled icon="arrow_left" size="sm" />,
    center: 'Корзина',
  };
};
component.cartActive = false;

export default component;
