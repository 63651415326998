import React from 'react';
import loadable from '@loadable/component';
import { getCart, getStorages } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';

const component = loadable(() => import('./OrderPageContainer'));
component.preloadData = [
  getCart,
  getStorages,
];
component.getHeader = () => {
  return {
    left:   <Button tag="a" to="/cart" filled icon="arrow_left" size="sm" />,
    center: 'Оформление заказа',
  };
};
component.cartActive = false;

export default component;
