import React from 'react';
import loadable from '@loadable/component';
import * as actions from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';
import { catalogPage } from '../../../utils/links';

const component = loadable(() => import('./PartsPageContainer'));
component.preloadData = process.env.CLIENT ? [
  actions.getSearch,
  actions.getBreadcrumbs,
  actions.getPartnamesExisting,
  actions.getAttributes,
] : [
  actions.getUniversalPage,
];
component.getHeader = (params) => {
  return {
    left:   <Button tag="a" to={catalogPage({ ...params, generation: null })} filled icon="arrow_left" size="sm" />,
    center: 'Каталог',
  };
};

export default component;
