import React from 'react';
import loadable from '@loadable/component';
import { getPart, getBreadcrumbs } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';
import { catalogPage } from '../../../utils/links';

const component = loadable(() => import('./DetailPageContainer'));
component.preloadData = [
  getBreadcrumbs,
  getPart,
];
component.getHeader = (params) => {
  return {
    left:   <Button tag="a" to={catalogPage(params)} filled icon="arrow_left" size="sm" />,
    center: 'Каталог',
  };
};

export default component;
