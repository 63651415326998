import React from 'react';
import loadable from '@loadable/component';
import { getStaticPage } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';

const component = loadable(() => import('./StaticPageContainer'));
component.preloadData = [
  getStaticPage,
];
component.getHeader = () => {
  return {
    left:   <Button tag="a" to="/" filled icon="arrow_left" size="sm" />,
    center: 'Страница',
  };
};

export default component;
