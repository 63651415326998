export default {
  App:                    require('./../mobile/containers/App/index').default,
  BrandList:              require('./../mobile/containers/BrandsPage/index').default,
  CartPage:               require('./../mobile/containers/CartPage/index').default,
  CatalogPage:            require('./../mobile/containers/PartsPage/index').default,
  CatalogModelsPage:      require('./../mobile/containers/ModelsPage/index').default,
  CatalogGenerationsPage: require('./../mobile/containers/GenerationsPage/index').default,
  CheckOrderPage:         require('./../mobile/containers/CheckOrderPage/index').default,
  ConfirmPage:            require('./../mobile/containers/ConfirmPage/index').default,
  DetailPage:             require('./../mobile/containers/DetailPage/index').default,
  ForgotPage:             require('./../mobile/containers/ForgotPage/index').default,
  FrontPage:              require('./../mobile/containers/FrontPage/index').default,
  LoginPage:              require('./../mobile/containers/LoginPage/index').default,
  NewsPage:               require('./../desktop/containers/NewsPage/index').default,
  NewsSinglePage:         require('./../desktop/containers/NewsSinglePage/index').default,
  NotFound:               require('./../desktop/components/NotFound/index').default,
  OrderPage:              require('./../mobile/containers/OrderPage/index').default,
  OrdersPage:             require('./../mobile/containers/OrdersPage/index').default,
  OrdersSinglePage:       require('./../mobile/containers/OrdersSinglePage/index').default,
  PartnersPage:           require('./../mobile/containers/PartnersPage/index').default,
  PaymentPage:            require('./../mobile/containers/PaymentPage/index').default,
  ProfilePage:            require('./../mobile/containers/ProfilePage/index').default,
  RegisterPage:           require('./../mobile/containers/RegisterPage/index').default,
  ResetPage:              require('./../mobile/containers/ResetPage/index').default,
  SearchPage:             require('./../desktop/components/NotFound/index'),
  StaticPage:             require('./../mobile/containers/StaticPage/index').default,
  // TestPage:       import('./../desktop/containers/TestPage/index').default
  // CabinetPage:        Loadable({ loader: () => import('./../mobile/containers/CabinetPage/index').default, loading }),
};
