import React from 'react';
import loadable from '@loadable/component';
import { getGenerations, getBreadcrumbs } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';
import { catalogPage } from '../../../utils/links';

const component = loadable(() => import('./GenerationsPageContainer'));
component.preloadData = [
  getGenerations,
  getBreadcrumbs,
];
component.getHeader = (params) => {
  return {
    left:   <Button tag="a" to={catalogPage({ ...params, model: null })} filled icon="arrow_left" size="sm" />,
    center: 'Выбор авто',
  };
};

export default component;
