import React from 'react';
import loadable from '@loadable/component';
import { getOrders } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';

const component = loadable(() => import('./OrdersPageContainer'));
component.preloadData = [
  getOrders,
];
component.getHeader = () => {
  return {
    left:   <Button tag="a" to="/" filled icon="arrow_left" size="sm" />,
    center: 'Личный кабинет',
  };
};

export default component;
