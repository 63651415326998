import React from 'react';
import loadable from '@loadable/component';
import { getModels, getBreadcrumbs } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';

const component = loadable(() => import('./ModelsPageContainer'));
component.preloadData = [
  getModels,
  getBreadcrumbs,
];
component.getHeader = (params) => {
  return {
    left:   <Button tag="a" to={`/${params.carType}`} filled icon="arrow_left" size="sm" />,
    center: 'Выбор авто',
  };
};

export default component;
