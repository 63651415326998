import React from 'react';
import loadable from '@loadable/component';
import { getOrder } from '../../../redux/api/redux-connect-actions';
import Button from '../../components/Button';

const component = loadable(() => import('./OrdersSinglePageContainer'));
component.preloadData = [
  getOrder,
];
component.getHeader = () => {
  return {
    left:   <Button tag="a" to="/orders" filled icon="arrow_left" size="sm" />,
    center: 'Личный кабинет',
  };
};

export default component;
